"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitCaCxData = void 0;
const getCdId_1 = require("../utils/getCdId");
const createTicketInfo_1 = require("../utils/createTicketInfo");
const PARSER_ID_MAP = {
    'moh-basis-raw': 'moh/html/basis',
    'moh-familie-raw': 'moh/html/familie',
    'moh-nationaliteit-raw': 'moh/html/nationaliteit',
    'moh-paspoort-id-kaart-raw': 'moh/html/paspoortid',
    'mbd-vooraf-ingevulde-gegevens': '',
    'uwv-verzekeringsbericht': '',
    'mpo-pensioengegevens': '',
    'mpo-pensioengegevens-pdf': '',
};
const DATASOURCE_MAP = {
    'moh-basis-raw': 'MOH',
    'moh-familie-raw': 'MOH',
    'moh-nationaliteit-raw': 'MOH',
    'moh-paspoort-id-kaart-raw': 'MOH',
    'mbd-vooraf-ingevulde-gegevens': 'VIA',
    'uwv-verzekeringsbericht': 'UWV',
    'mpo-pensioengegevens': 'UPO',
    'mpo-pensioengegevens-pdf': 'UPO',
};
const VALID_DATASOURCES = ['MOH', 'VIA', 'UWV', 'UPO', 'DUO'];
const ITEM_ENDPOINT_MAP = {
    dev: 'https://api.dev.ocm.ockto-test.cloud/ibs-obs/document',
    test: 'https://api.tst.ocm.ockto-test.cloud/ibs-obs/document',
    acct: 'https://api.acc.ocm.ockto.cloud/ibs-obs/document',
    prod: 'https://api.prd.ocm.ockto.cloud/ibs-obs/document',
};
const CASE_ENDPOINT_MAP = {
    dev: 'https://api.dev.ocm.ockto-test.cloud/ibs-obs/aggregate',
    test: 'https://api.tst.ocm.ockto-test.cloud/ibs-obs/aggregate',
    acct: 'https://api.acc.ocm.ockto.cloud/ibs-obs/aggregate',
    prod: 'https://api.prd.ocm.ockto.cloud/ibs-obs/aggregate',
};
function submitCaCxData(blobList_1, jwt_1, env_1) {
    return __awaiter(this, arguments, void 0, function* (blobList, jwt, env, suppressErrors = false) {
        const caCxData = getCaCxData(blobList);
        const ticketInfo = (0, createTicketInfo_1.createTicketInfo)(jwt);
        const caseId = ticketInfo.caseId;
        const blobs = [...blobList];
        const dataSourceTypes = getDataSourceTypes(blobs);
        if (!caseId) {
            if (suppressErrors) {
                console.error('[submitCaCxData]: Could not extract caseId from token');
                return;
            }
            else {
                throw new Error('[submitCaCxData]: Could not extract caseId from token');
            }
        }
        const cdId = (0, getCdId_1.getCdId)(blobs, caseId);
        const submits = [];
        if (caCxData.mohBlobList) {
            submits.push(submitJson(caCxData.mohBlobList, 'MOH', cdId, jwt, env));
        }
        if (caCxData.uwvBlob) {
            submits.push(submitJson(caCxData.uwvBlob, 'UWV', cdId, jwt, env));
        }
        if (caCxData.mbdBlob) {
            submits.push(submitJson(caCxData.mbdBlob, 'VIA', cdId, jwt, env));
        }
        if (caCxData.mpoBlobList) {
            submits.push(submitJson(caCxData.mpoBlobList, 'UPO', cdId, jwt, env));
        }
        if (caCxData.duoBlobList) {
            submits.push(submitJson(caCxData.duoBlobList, 'DUO', cdId, jwt, env));
        }
        let submitsDone = 0;
        submits.forEach((submit) => submit
            .then(() => {
            submitsDone++;
            document.dispatchEvent(new CustomEvent('aqopi-send-progress', {
                detail: { progress: (submitsDone / submits.length) * 100 },
            }));
        })
            .catch(() => { }));
        try {
            yield Promise.all(submits);
            yield submitCaseComplete(dataSourceTypes, cdId, jwt, env);
            return { cdId };
        }
        catch (err) {
            const msg = err instanceof Error ? err.message : err;
            if (suppressErrors) {
                console.log('[submitCaCxData]: Could not submit data to server: ' + msg);
                return;
            }
            else {
                throw new Error('[submitCaCxData]: Could not submit data to server: ' + msg);
            }
        }
    });
}
exports.submitCaCxData = submitCaCxData;
function getCaCxData(blobList) {
    const duoBlobs = filterBlobsByParserId(blobList, new RegExp('duo-klantbeeld|duo-schuldhistorie|duo-mijn-schulden'));
    return {
        uwvBlob: getBlobByParserId(blobList, 'uwv-verzekeringsbericht'),
        mbdBlob: getBlobByParserId(blobList, 'mbd-vooraf-ingevulde-gegevens'),
        mpoXmlBlob: getBlobByParserId(blobList, 'mpo-pensioengegevens'),
        mpoBlob: getBlobByParserId(blobList, 'mpo-pensioengegevens-pdf'),
        mpoBlobList: filterBlobsByParserId(blobList, new RegExp('mpo-pensioengegevens|mpo-pensioengegevens-pdf')),
        mohBlobList: filterBlobsByParserId(blobList, new RegExp('moh-basis-raw|moh-familie-raw|moh-nationaliteit-raw|moh-paspoort-id-kaart-raw')),
        duoBlobList: duoBlobs && duoBlobs.length === 3 ? duoBlobs : [],
    };
}
function createDocumentWrapper(blob) {
    const path = PARSER_ID_MAP[blob.data.parserId];
    return {
        data: blob,
        meta: path
            ? [
                {
                    key: 'url',
                    value: path,
                },
            ]
            : [], //Empty list if no MOH present
    };
}
function submitJson(blobs, dataSourceType, cdId, ticket, env) {
    return __awaiter(this, void 0, void 0, function* () {
        const blobList = (Array.isArray(blobs) ? blobs : [blobs]).filter((item) => !!item);
        const url = ITEM_ENDPOINT_MAP[env];
        const data = {
            meta: {
                cdId,
                dataSourceType,
                // TODO following should not be provided by client
                consentExpiresAt: new Date(new Date().setDate(new Date().getDate() + 90)).toISOString(),
                consentCode: '002',
            },
            data: {
                documents: blobList
                    .filter((val) => PARSER_ID_MAP[val.data.parserId] !== undefined)
                    .map((item) => createDocumentWrapper(item)),
            },
        };
        if (blobList.length === 0) {
            return Promise.resolve();
        }
        return fetchData(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ticket,
            },
            body: JSON.stringify(data),
        });
    });
}
function submitCaseComplete(dataSourceTypes, cdId, ticket, env) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = CASE_ENDPOINT_MAP[env];
        const data = {
            meta: {
                cdId,
                odmSetName: '',
                ocktoType: 'IBS',
                dataSourceTypes,
            },
            data: {},
        };
        return fetchData(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ticket,
            },
            body: JSON.stringify(data),
        });
    });
}
function getDataSourceTypes(blobs) {
    const blobList = Array.isArray(blobs) ? blobs : [blobs];
    const dataSources = [
        ...new Set(blobList
            .map((item) => DATASOURCE_MAP[item.data.parserId])
            .filter((item) => VALID_DATASOURCES.includes(item))),
    ];
    return Array.from(new Set(dataSources));
}
function getBlobByParserId(blobs, parserId) {
    try {
        return blobs.find((item) => item.data.parserId === parserId) || null;
    }
    catch (error) {
        console.debug('[getBlobByParserId]:', error);
        return null;
    }
}
function filterBlobsByParserId(blobs, regEx) {
    try {
        return blobs.filter((item) => item.data.parserId.match(regEx));
    }
    catch (error) {
        console.debug('[filterBlobsByParserId]:', error);
        return [];
    }
}
function fetchData(input, init) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(input, init);
            if (!response.ok) {
                throw new Error(`HTTP status error: ${response.status}`);
            }
            return response;
        }
        catch (error) {
            console.debug('[fetchData]:', error);
            throw error;
        }
    });
}
