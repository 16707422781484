"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceMbdXmlBsn = exports.viaBsnWorkaround = exports.isValidBSN = exports.generateBSN = void 0;
/** Generator from testnummers.nl **/
const generateBSN = () => {
    const randomDigit = () => Math.floor(Math.random() * 10);
    let [Nr9, Nr8, Nr7, Nr6, Nr5, Nr4, Nr3, Nr2] = [
        Math.floor(Math.random() * 7),
        randomDigit(),
        randomDigit(),
        randomDigit(),
        randomDigit(),
        randomDigit(),
        randomDigit(),
        randomDigit(),
    ];
    // Ensure the first two digits are not both zero
    if (Nr9 === 0 && Nr8 === 0) {
        Nr8 = 1;
    }
    // Calculate check digit
    const SofiNr = 9 * Nr9 + 8 * Nr8 + 7 * Nr7 + 6 * Nr6 + 5 * Nr5 + 4 * Nr4 + 3 * Nr3 + 2 * Nr2;
    let Nr1 = SofiNr % 11;
    // Adjust Nr1 if it exceeds 9
    if (Nr1 > 9) {
        Nr1 = Nr2 > 0 ? (Nr2 -= 1, 8) : (Nr2 += 1, 1);
    }
    const result = `${Nr9}${Nr8}${Nr7}${Nr6}${Nr5}${Nr4}${Nr3}${Nr2}${Nr1}`;
    return result.startsWith('0') ? result.substring(1) : result;
};
exports.generateBSN = generateBSN;
const matchAllPolyfill = (target, regex) => {
    // Ensure the regex has the global flag set, as `matchAll` requires it
    if (!regex.global) {
        throw new Error("The regular expression must have the global flag set.");
    }
    const matches = [];
    let match;
    // Using exec in a loop to capture all matches
    while ((match = regex.exec(target)) !== null) {
        matches.push(match);
    }
    return matches;
};
const replaceAllPolyfill = (target, searchValue, replacement) => {
    // If searchValue is a string, we escape it and convert to global regex
    if (typeof searchValue === "string") {
        const escapedSearch = searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        searchValue = new RegExp(escapedSearch, "g");
    }
    else if (!searchValue.global) {
        // If searchValue is a RegExp, ensure it has the global flag
        throw new Error("The regular expression must have the global flag set.");
    }
    // Replace all occurrences
    return target.replace(searchValue, replacement);
};
const replaceMbdXmlBsn = (xml, regexp, hashedBsn) => {
    let result = xml;
    try {
        const matchGroups = matchAllPolyfill(result, regexp);
        for (let matchGroup of matchGroups) {
            if (matchGroup.length === 4) {
                result = replaceAllPolyfill(result, matchGroup[2], hashedBsn);
            }
        }
    }
    catch (error) {
        console.error('Error MBD XML BSN', error);
    }
    return result;
};
exports.replaceMbdXmlBsn = replaceMbdXmlBsn;
const isValidBSN = (bsn) => {
    // Check if the input is 8 or 9 digits long
    if (!/^\d{8,9}$/.test(bsn)) {
        return false;
    }
    const target = bsn.length === 8 ? '0' + bsn : bsn;
    const values = target.split('');
    const [a, b, c, d, e, f, g, h, i] = values.map(Number);
    let result = 0;
    result = 9 * a + 8 * b + 7 * c + 6 * d + 5 * e + 4 * f + 3 * g + 2 * h + -1 * i;
    return result > 0 && result % 11 === 0;
};
exports.isValidBSN = isValidBSN;
const viaBsnWorkaround = (request) => {
    try {
        if (request.event === '&download-success' && request.id === 'mbd-vooraf-ingevulde-gegevens') {
            let xml = request.payload;
            xml = replaceMbdXmlBsn(xml, /(IdentNrAangever>)(\d+)(<\/)/gm, generateBSN());
            xml = replaceMbdXmlBsn(xml, /(IdentNrRelatie>)(\d+)(<\/)/gm, generateBSN());
            request.payload = xml;
        }
    }
    catch (error) {
        console.log('Error stripping BSN', error);
    }
};
exports.viaBsnWorkaround = viaBsnWorkaround;
