"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataSourceTypes = exports.getCdId = void 0;
const DATASOURCE_MAP = {
    'moh-basis-raw': 'MOH',
    'moh-familie-raw': 'MOH',
    'moh-nationaliteit-raw': 'MOH',
    'moh-paspoort-id-kaart-raw': 'MOH',
    'mbd-vooraf-ingevulde-gegevens': 'VIA',
    'uwv-verzekeringsbericht': 'UWV',
    'mpo-pensioengegevens': 'UPO',
    'mpo-pensioengegevens-pdf': 'UPO'
};
// const VALID_DATASOURCES = ['MOH', 'VIA', 'UWV', 'UPO']; VIA ignored for the time being
const VALID_DATASOURCES = ['MOH', 'UWV', 'UPO'];
const getDataSourceTypes = (blobs) => {
    const blobList = Array.isArray(blobs) ? blobs : [blobs];
    const dataSources = [...new Set(blobList.map(item => { var _a; return DATASOURCE_MAP[(_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.parserId]; }).filter(item => VALID_DATASOURCES.indexOf(item) >= 0))];
    return dataSources.length > 0 ? Array.from(new Set(dataSources)) : '';
};
exports.getDataSourceTypes = getDataSourceTypes;
const getCdId = (blobs, Id) => {
    try {
        const today = new Date();
        const ExpiryDate = new Date(today.setDate(today.getDate() + 90)).toISOString();
        const DataSources = getDataSourceTypes(blobs);
        return btoa(JSON.stringify({
            consumerData: {
                Id,
                DataSources,
                ExpiryDate,
                Source: 'iWize'
            }
        }));
    }
    catch (error) {
        console.error('Error generating cdId', error);
        return '';
    }
};
exports.getCdId = getCdId;
