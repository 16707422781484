"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AqopiSetStickyCredsRequest = exports.AqopiUninstallRequest = exports.AqopiPingRequest = exports.AqopiSetLoggingStatusRequest = exports.AqopiHandleActionRequestEvent = exports.AqopiFocusContentRequestEvent = exports.AqopiFocusRequestorRequestEvent = exports.AqopiCloseTabRequestEvent = exports.AqopiSetTemplatesRequestEvent = exports.AqopiOpenTabRequestEvent = exports.AqopiMessageEvent = exports.AqopiEvent = exports.AqopiEventType = void 0;
var AqopiEventType;
(function (AqopiEventType) {
    AqopiEventType["Message"] = "aqopi-msg";
    AqopiEventType["OpenTabRequest"] = "aqopi-open-tab-request";
    AqopiEventType["SetTemplatesRequest"] = "aqopi-set-templates-request";
    AqopiEventType["CloseTabRequest"] = "aqopi-close-tab-request";
    AqopiEventType["FocusRequestorRequest"] = "aqopi-focus-requestor-request";
    AqopiEventType["FocusContentRequest"] = "aqopi-focus-content-request";
    AqopiEventType["HandleActionRequest"] = "aqopi-handle-action-request";
    AqopiEventType["SetLoggingStatusRequest"] = "aqopi-set-logging-status-request";
    AqopiEventType["PingRequest"] = "aqopi-ping-request";
    AqopiEventType["UninstallRequest"] = "aqopi-uninstall-request";
    AqopiEventType["SetStickyCredsRequest"] = "aqopi-set-sticky-creds-request";
    AqopiEventType["isInstalled"] = "aqopi-is-installed";
})(AqopiEventType || (exports.AqopiEventType = AqopiEventType = {}));
class AqopiEvent extends CustomEvent {
    constructor(type, detail) {
        super(type, { detail });
    }
}
exports.AqopiEvent = AqopiEvent;
class AqopiMessageEvent extends AqopiEvent {
    constructor(message) {
        super(AqopiEventType.Message, JSON.stringify(message));
    }
}
exports.AqopiMessageEvent = AqopiMessageEvent;
class AqopiOpenTabRequestEvent extends AqopiEvent {
    constructor(cookies, selectorMap) {
        super(AqopiEventType.OpenTabRequest, JSON.stringify({ cookies, selectorMap }));
    }
}
exports.AqopiOpenTabRequestEvent = AqopiOpenTabRequestEvent;
class AqopiSetTemplatesRequestEvent extends AqopiEvent {
    constructor(templates) {
        super(AqopiEventType.SetTemplatesRequest, JSON.stringify({ templates }));
    }
}
exports.AqopiSetTemplatesRequestEvent = AqopiSetTemplatesRequestEvent;
class AqopiCloseTabRequestEvent extends AqopiEvent {
    constructor() {
        super(AqopiEventType.CloseTabRequest);
    }
}
exports.AqopiCloseTabRequestEvent = AqopiCloseTabRequestEvent;
class AqopiFocusRequestorRequestEvent extends AqopiEvent {
    constructor() {
        super(AqopiEventType.FocusRequestorRequest);
    }
}
exports.AqopiFocusRequestorRequestEvent = AqopiFocusRequestorRequestEvent;
class AqopiFocusContentRequestEvent extends AqopiEvent {
    constructor() {
        super(AqopiEventType.FocusContentRequest);
    }
}
exports.AqopiFocusContentRequestEvent = AqopiFocusContentRequestEvent;
class AqopiHandleActionRequestEvent extends AqopiEvent {
    constructor(action) {
        super(AqopiEventType.HandleActionRequest, JSON.stringify({ action }));
    }
}
exports.AqopiHandleActionRequestEvent = AqopiHandleActionRequestEvent;
class AqopiSetLoggingStatusRequest extends AqopiEvent {
    constructor(loggingStatus) {
        super(AqopiEventType.SetLoggingStatusRequest, JSON.stringify({ loggingStatus }));
    }
}
exports.AqopiSetLoggingStatusRequest = AqopiSetLoggingStatusRequest;
class AqopiPingRequest extends AqopiEvent {
    constructor() {
        super(AqopiEventType.PingRequest);
    }
}
exports.AqopiPingRequest = AqopiPingRequest;
class AqopiUninstallRequest extends AqopiEvent {
    constructor() {
        super(AqopiEventType.UninstallRequest);
    }
}
exports.AqopiUninstallRequest = AqopiUninstallRequest;
class AqopiSetStickyCredsRequest extends AqopiEvent {
    constructor(sticky) {
        super(AqopiEventType.SetStickyCredsRequest, JSON.stringify({ sticky }));
    }
}
exports.AqopiSetStickyCredsRequest = AqopiSetStickyCredsRequest;
